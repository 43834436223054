<template>
	<div class="detail">
		<div class="main">
			<div class="pic">
				<div class="big"><img :src="detail.cover" width="100%" alt="" /></div>
				<div class="album">
					<Album @showIt="showIt" :data="detail.pics"></Album>
				</div>
				<div class="label">
					<div class="name"><span class="iconfont icon-label"></span>Canguro</div>
					<div class="title">Exterior</div>
					<div class="content">{{ detail.exterior }}</div>
					<div class="title">Cuidado de la prenda</div>
					<div class="content">
						<img v-for="val in detail.cuidado" :src="val.icon" />
					</div>
				</div>
			</div>
			<div class="goodsInfo">
				<div class="name">{{ detail.spuName }}</div>
				<div class="price">
					<span class="current">€{{ detail.price }}</span>
					<span class="origin">{{
            detail.originPrice == "0.00" ? "" : "€" + detail.originPrice
          }}</span>
				</div>
				<!-- <div class="title">颜色:</div>
				<div class="color">
					<div
						v-for="val in color.list"
						:class="`${val.id == color.current.id ? 'active' : ''} item`"
						:style="`background: ${val.color};`"
						@click="color.current = val"
					></div>
				</div>
				<div class="line"></div> -->
				<!-- <template v-for="val in detail.specs">
					<div class="title">{{val.specName}}:</div>
					<div class="sizes">
						<div class="item" v-for="v in val.specs">{{v.value}}</div>
					</div>
					<div class="line"></div>
				</template> -->
				<div class="title">{{ $t("goodDetail.specification") }}:</div>
				<div class="sizes">
					<div v-for="(val, index) in detail.skus" :key="index" :class="`item ${active.skuId == val.skuId ? 'active' : ''} ${
              val.specInfo ? 'border' : ''
            }`" @click="
              active.skuId = val.skuId;
              detail.price = val.price;
            ">
						{{ val.specInfo }}
					</div>
				</div>
				<!-- 		<div class="line"></div> -->
				<div class="buttons">
					<div class="btn deep" @click="addCart">{{ $t("goodDetail.addCart") }}</div>
					<div class="btn" @click="$bus.$emit('addLike', detail.spuId)">
						{{ $t("goodDetail.addCollect") }}
					</div>
				</div>
				<div class="title">{{ $t("tip.share") }}:</div>
				<div class="share">
					<img class="item" src="../../assets/icon/facebook.png" @click="handleFaceBook()" />
					<img class="item" src="../../assets/icon/camera.png" @click="handleCamera()" />
					<!-- <img class="item" src="../../assets/icon/twitter.png" /> -->
				</div>
				<div class="title">{{ $t("goodDetail.detail") }}:</div>
				<div class="content" v-html="detail.content"></div>
				<el-collapse>
					<el-collapse-item>
						<template slot="title">
							<div class="tip">
								<img src="../../assets/icon/cloth.png" />
								<span>{{ $t("tip.washTip") }}</span>
								<i class="el-icon-arrow-down"></i>
							</div>
						</template>
						<div class="content">
							<div v-for="(item, index) in instructions" :key="index" v-html="item.content"></div>
						</div>
					</el-collapse-item>
					<el-collapse-item>
						<template slot="title">
							<div class="tip">
								<img src="../../assets/icon/car.png" />
								<span>{{ $t("tip.postTip") }}</span>
								<i class="el-icon-arrow-down"></i>
							</div>
						</template>
						<div class="content">
							<div v-for="(item, index) in distribution" :key="index" v-html="item.content"></div>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
		</div>
		<div class="hot">
			<div class="title">{{ $t("tip.detailRecommend") }}</div>
			<div class="goods">
				<div class="item" v-for="val in recommand">
					<GoodsItem :detail="val"></GoodsItem>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Album from "./album.vue";
	import Api from "../../api/request.js";
	import GoodsItem from "../../components/Goods/listItem.vue";
	export default {
		name: "GoodsDetail",
		components: {
			GoodsItem,
			Album,
		},
		watch: {
			$route(res) {
				if (res.path == "/GoodsDetail") {
					this.getDetail();
				}
			},
		},
		data() {
			return {
				color: {
					list: [{
							id: 1,
							color: "#000000"
						},
						{
							id: 2,
							color: "#E0E4E3"
						},
						{
							id: 3,
							color: "#7D5337"
						},
						{
							id: 4,
							color: "#BE2B35"
						},
					],
					current: {
						id: 1,
						color: "#000000"
					},
				},
				size: {
					list: [{
							id: 1,
							size: "XS"
						},
						{
							id: 2,
							size: "S"
						},
						{
							id: 3,
							size: "M"
						},
						{
							id: 4,
							size: "L"
						},
						{
							id: 5,
							size: "XL"
						},
					],
					current: {
						id: 1,
						color: "XS"
					},
				},
				detail: {},
				active: {
					skuId: "",
				},
				recommand: [],
				instructions: "", //洗衣指南
				distribution: "", // 配送
			};
		},
		created() {
			this.getDetail();
		},
		methods: {
			getDetail() {
				this.$dialog.loading();
				Promise.all([
					new Promise((resolve, reject) => {
						Api.Goods.detail({
							spuId: this.$route.query.id
						}).then((res) => {
							this.detail = res.data.data;
							this.detail.pics.unshift(this.detail.cover);
							Api.Goods.recommand({
								spu_ids: res.data.data.recommendSpu
							}).then((res) => {
								this.recommand = res.data.data;
								resolve();
							});
						});
					}),
					new Promise((resolve, reject) => {
						Api.AboutUs.article({
							page: 1,
							pageLimit: 10,
							articleMenuId: 3,
						}).then((res) => {
							this.instructions = res.data.data.list;
							resolve();
						});
					}),
					new Promise((resolve, reject) => {
						Api.AboutUs.article({
							page: 1,
							pageLimit: 10,
							articleMenuId: 4,
						}).then((res) => {
							this.distribution = res.data.data.list;
							resolve();
						});
					}),
				]).then((res) => {
					this.$dialog.close();
				});
			},
			addCart() {
				if (this.active.skuId == "") {
					this.$bus.$emit("addCart", this.detail.skus[0].skuId);
				} else {
					this.$bus.$emit("addCart", this.active.skuId);
				}
			},
			showIt(url) {
				this.detail.cover = url;
			},
			handleFaceBook() {
				window.location.href =
					"https://www.facebook.com/share/18fUP1n9sD/";
			},
			handleCamera() {
				window.location.href = "https://www.instagram.com/alesogarcia/profilecard/?igsh=MTJvdTZyajRwcXl6cA==";
			},
		},
	};
</script>

<style lang="less" scoped>
	.cate {
		padding: 0 46px;
		height: 60px;
		font-size: 14px;
		font-weight: 400;
		display: flex;
		align-items: center;

		.devide {
			height: 13px;
			width: 1px;
			background: #000;
			margin: 0 1em;
		}
	}

	.main {
		width: 1300px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;

		.pic {
			width: 600px;

			.big {
				height: 690px;
				overflow: hidden;
			}

			.album {
				overflow: hidden;
				height: 135px;
				margin-top: 20px;
			}

			.label {
				margin-top: 20px;

				.name {
					font-size: 14px;
					font-weight: bold;
					display: flex;
					align-items: center;
					color: #666;

					&>span {
						font-size: 18px;
						margin-right: 5px;
					}
				}

				.title {
					font-size: 14px;
					color: #123178;
					margin-top: 10px;
				}

				.content {
					font-size: 15px;
					color: #333;
					display: flex;

					&>img {
						width: 30px;
						height: 30px;
						border-radius: 50%;
						background: #ccc;
						margin-right: 12px;
						object-fit: cover;
					}
				}
			}
		}

		.goodsInfo {
			width: 600px;

			.name {
				font-size: 28px;
				font-weight: bold;
				line-height: 40px;
			}

			.title {
				height: 100px;
				font-size: 16px;
				line-height: 100px;
				font-weight: bold;
			}

			.line {
				height: 1px;
				background: #757575;
				margin-top: 20px;
			}

			.price {
				margin: 20px 0 40px;

				.current {
					font-size: 18px;
					font-weight: bold;
					line-height: 25px;
				}

				.origin {
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					margin-left: 18px;
					text-decoration: line-through;
				}
			}

			.color {
				display: flex;

				.item {
					width: 36px;
					height: 36px;
					border-radius: 50%;
					margin-right: 40px;
					cursor: pointer;

					&.active {
						&::before {
							content: "";
							display: block;
							width: 24px;
							height: 24px;
							border: 4px solid #fff;
							border-radius: 50%;
							margin: 2px auto 0;
						}
					}
				}
			}

			.sizes {
				display: flex;
				flex-wrap: wrap;

				.item {
					font-size: 18px;
					font-weight: bold;
					line-height: 25px;
					margin-right: 40px;
					padding: 5px 10px;
					border-radius: 6px;
					cursor: pointer;
					margin-bottom: 15px;

					&.border {
						border: 1px solid #123178;
					}

					&.active {
						background: #123178;
						color: #fff;
					}
				}
			}

			.buttons {
				display: flex;
				margin-top: 40px;

				.btn {
					height: 48px;
					padding: 0 50px;
					background: #ffffff;
					border-radius: 6px;
					border: 1px solid #123178;
					text-align: center;
					line-height: 48px;
					font-size: 12px;
					font-weight: 600;
					color: #123178;
					cursor: pointer;
					margin-right: 40px;

					&.deep {
						background-color: #e8c9a0;
						color: #fff;
						border: 1px solid #e8c9a0;
					}

					&:hover {
						background-color: #efefef;
					}
				}

				.deep:hover {
					background-color: #eecc77;
				}
			}

			.share {
				display: flex;

				.item {
					width: 24px;
					height: 24px;
					margin-right: 20px;
					cursor: pointer;
				}
			}

			.content {
				&>p {
					margin-bottom: 20px;
					font-size: 14px;
					color: #333;
				}
			}

			.tip {
				display: flex;
				align-items: center;
				// margin-top: 40px;
				cursor: pointer;

				&>img {
					width: 20px;
					height: 18px;
				}

				&>span {
					margin: 0 24px 0 16px;
					font-size: 14px;
					font-weight: 400;
				}

				&>i {
					font-size: 12px;
					font-weight: bolder;
				}
			}
		}
	}

	/deep/.el-collapse {
		border: none;

		.el-collapse-item {
			margin-top: 20px;

			.el-collapse-item__header {
				border-bottom: none;

				.el-collapse-item__arrow {
					display: none;
				}
			}
		}

		.el-collapse-item__wrap {
			border-bottom: none;
		}

		.el-collapse-item__content {
			padding-top: 20px;
		}
	}

	.hot {
		padding-left: 7px;
		overflow: hidden;

		.title {
			font-size: 28px;
			font-weight: bold;
			line-height: 40px;
			margin: 60px 0 40px;
		}

		.goods {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 20px;

			.item {
				width: calc((100vw - 32px) / 4);
				margin-right: 6px;
				margin-top: 15px;
			}

			@media screen and (max-width: 650px) {
				.item {
					width: calc((100vw - 32px) / 2);
					margin-right: 6px;
					margin-top: 15px;
				}
			}
		}
	}
</style>
